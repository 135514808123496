<template>
  <section class="content">
    <table class="table table-hover" ref="tblrekrutmen">
      <thead>
        <tr>
          <th>TANGGAL</th>
          <th>WAKTU</th>
          <th>NAMA</th>
          <th>POSISI DILAMAR</th>
          <th>NO. HP/WA</th>
          <th>KONFIRMASI HADIR</th>
          <th>HASIL INTERVIEW</th>
          <th>CV</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="errors.length" class="alert alert-danger alert-dismissible fade show" role="alert">
                <b>Please correct the following error(s):</b>
                <ul>
                  <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
                </ul>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </p>
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Tanggal</label>
                  <datepicker v-model="form.tanggal" id="tanggal" />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Waktu</label>
                  <div class="row">
                    <timepicker placeholder="07:00" v-model="form.waktu" />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Nama</label>
                  <input
                    id="kandidat_nama"
                    class="form-control"
                    v-model="form.kandidat_nama"
                    type="text"
                    name="kandidat_nama"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Email</label>
                  <input
                    id="kandidat_email"
                    class="form-control"
                    v-model="form.kandidat_email"
                    type="text"
                    name="kandidat_email"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">NO. HP/WA</label>
                  <input
                    id="kandidat_phone"
                    class="form-control"
                    v-model="form.kandidat_phone"
                    type="number"
                    name="kandidat_phone"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Posisi Dilamar</label>
                  <input
                    id="kandidat_posisi"
                    class="form-control"
                    v-model="form.kandidat_posisi"
                    type="text"
                    name="kandidat_posisi"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Lokasi</label>
                  <textarea
                    class="form-control"
                    id="lokasi"
                    rows="3"
                    v-model="form.lokasi"
                    placeholder="Tuliskan lokasi"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Konfirmasi Hadir</label>
                  <select
                    class="custom-select"
                    v-model="form.is_konfirmasi_hadir"
                  >
                    <option selected disabled>Pilih</option>
                    <option value="0">Menunggu</option>
                    <option value="1">Hadir</option>
                    <option value="2">Tidak Hadir</option>
                  </select>
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label"
                    >Keterangan Hasil Interview</label
                  >
                  <textarea
                    class="form-control"
                    id="note_hasil"
                    rows="3"
                    v-model="form.note_hasil"
                    placeholder="Tuliskan hasil interview"
                  ></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label for="inputFile">CV</label>
                        <input type="hidden" name="img_code" value="" v-model="form.filecode" id="img_code" />
                    <div class="custom-file">
                        <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                        <label class="custom-file-label" for="inputFile">Choose file</label>
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import datepicker from "@/components/Datepicker";
import timepicker from "@/components/Timepicker";
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
  name: "",
  data() {
    return {
      errors: [],
      method: "",
      roles: "",
      formTitle: "Tambah Rekrutmen",
      form: {
        tanggal: "",
        waktu: "",
        kandidat_nama: "",
        kandidat_phone: "",
        kandidat_email: "",
        kandidat_posisi: "",
        lokasi: "",
        is_konfirmasi_hadir: "",
        note_hasil: "",
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
      if (e.srcElement.getAttribute('class') == 'btn btn-primary') {
          this.download(e.srcElement.getAttribute('data-href'));
      }
    },
    download: function(data) {
      window.open(window.$apiUrl+data, "_blank")
    },
    uploadFoto : function (file) {
        var file_data = $('#inputFile').prop('files');
        var form_data = new FormData();
        var fileInput = $('#inputFile')[0];
        $.each(fileInput.files, function(k,file){
          form_data.append('file', file);
        });
        form_data.append('subdir', '/jurnal-umum/');
        form_data.append('id', this.form.id);
        this.form_data = form_data
        return false
    },
    doUpload: function() {
        return authFetch('/management/cabang/upload_data', {
            method: 'POST',
            body: this.form_data,
            headers: {
            'Content-Type': null,
            }
        })
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var self = this;
      this.errors = [];
      var file_data = $('#inputFile').prop('files');
      var urlSubmit = "/administrasi/rekrutmen";
      if (this.method == "PUT")
        urlSubmit = "/administrasi/rekrutmen/" + this.form.id;

      if (file_data.length != 0) {
        this.doUpload().then((res) => {
            if (res.status === 201) {

            } else if (res.status === 400) {}

            return res.json();
            
        }).then(js => {
            if (!js.status) {
                for (var key in js.details) {
                    if (js.details.hasOwnProperty(key)) {
                        this.errors.push(js.details[key])
                    }
                }

                return;
            } else {
                if (js.message[0]) {
                    self.form.filecode = js.message[0].code;
                }

                var data = Object.keys(this.form)
                  .map(
                    (key) =>
                      encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
                  )
                  .join("&");

                authFetch(urlSubmit, {
                  method: this.method,
                  body: data,
                })
                  .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    return res.json();
                  })
                  .then((js) => {
                    this.errors = [];
                    if (!js.success) {
                      console.log(js.details);

                      for (var key in js.details) {
                        if (js.details.hasOwnProperty(key)) {
                          this.errors.push(js.details[key]);
                        }
                      }

                      return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal("hide");
                  });
            }
        })
      } else {
        var data = Object.keys(this.form)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
          )
          .join("&");

        authFetch(urlSubmit, {
          method: this.method,
          body: data,
        })
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            this.errors = [];
            if (!js.success) {
              console.log(js.details);

              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }

              return;
            }
            this.table.api().ajax.reload();
            $(e.formDialog).modal("hide");
          });
      }
      ev.preventDefault();
    },
  },
  components: {
    datepicker,
    timepicker,
    datePicker,
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblrekrutmen, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/administrasi/rekrutmen",
      columns: [
        { data: "tanggal" },
        { data: "waktu" },
        { data: "kandidat_nama" },
        { data: "kandidat_posisi" },
        { data: "kandidat_phone" },
        { data: "is_konfirmasi_hadir" },
        { data: "note_hasil" },
        { data: "filecode" },
      ],
      filterBy: [0, 1, 2, 3, 4, 6],
      rowCallback: function (row, data) {
        if (data.is_konfirmasi_hadir == 0) {
          $("td:eq(5)", row).html(`<h6 style="color:#FEB027;">MENUNGGU</h6>`);
        } else if (data.is_konfirmasi_hadir == 1) {
          $("td:eq(5)", row).html(`<h6 style="color:#208BFF;">HADIR</h6>`);
        } else if (data.is_konfirmasi_hadir == 2) {
          $("td:eq(5)", row).html(
            `<h6 style="color:#ED4660;">TIDAK HADIR</h6>`
          );
        }
        if(data.filecode)$('td:eq(7)', row).html('<a type="button" class="btn btn-primary" data-href="/file/download/'+data.filecode+'">Download</a>');
      },
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {
            tanggal: "",
            waktu: "",
            kandidat_nama: "",
            kandidat_phone: "",
            kandidat_email: "",
            kandidat_posisi: "",
            lokasi: "",
            note_hasil: "",
          };
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Rekrutmen";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Rekrutmen";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/administrasi/rekrutmen/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      }
    });
  },
};
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>
